/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

@import "spinner";

.search_spacing_block {
  margin-top: 4rem;

  @include breakpoint-down(mobile) {
    margin-top: 0;
  }
}

.section-search {
  min-height: 17rem;
  padding: 2rem;
  background: #1f1a31;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  line-height: 1;
  width: 100%;

  &.is-header-sicky {
    margin-top: calc(4.8rem + 1.5rem);

    @include breakpoint-down(mobile) {
      margin-top: calc(5.8rem + 1.5rem);
    }
  }

  .section-content {
    background: transparent;
    max-width: 80rem;
    width: 95%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .search_container {
      width: 100%;
      margin-bottom: 0;

      .search {
        form {
          display: flex;
          width: fit-content;
          align-items: stretch;
          height: 5rem;
          width: 100%;
          background: $c-white;
          box-shadow: 0px 1px 2px #0000001a;
          border-radius: 5px;
          position: relative;

          margin: 0;
          padding: 1.2rem 1rem;

          .search_tools_container {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: max-content;
            display: flex;
            align-items: center;

            .semantic-search-button {
              background: transparent;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 50px;

              span {
                font-weight: 900;
                font-size: 2.5rem;
              }
            }

            .clear_search_container {
              cursor: pointer;
              height: 100%;
              width: 50px;
              display: none;
              justify-content: center;
              align-items: center;

              span {
                font-size: 2.5rem;
              }
            }

            .divider {
              display: none;
              background-color: gray;
              height: 60%;
              opacity: 0.3;
              width: 1px;
              margin-inline: 1rem;

              @include breakpoint-down(mobile) {
                margin-inline: 0.3rem;
              }
            }
          }

          .suggestions {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            max-height: 200px;
            overflow-y: hidden;
            border-radius: 0 0 4px 4px;
            top: 94%;
            width: 100%;
            right: 0;
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.5);

            .divider_suggestion {
              height: 1px;
              background-color: gray;
              opacity: 0.3;
              margin: 10px;
            }

            .suggestion {
              cursor: pointer;
              margin: 10px;
              padding: 10px;
              border-radius: 5px;
              font-weight: 500;
            }

            .suggestion:hover {
              background-color: #f5f5f5;
            }
          }

          .search__field {
            background: transparent;
            border: none;
            outline: none;
            width: 100%;
            flex-grow: 1;
          }

          .search-spinner {
            display: none;
            background-color: #fff;
            margin-right: 10px;
            align-self: center;
            position: absolute;
            right: 0.2rem;
            width: 25px;
            height: 25px;
            z-index: 99;
          }

          .search__btn {
            background: transparent;
            border: none;
            outline: none;
            margin: 0 2rem 0 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0.2rem;
          }
        }

        #ai-search-form .search__field {
          @include breakpoint-down(mobile) {
            font-size: 1.3rem;
            flex-grow: 0;
            width: 55%;
          }
        }

        .buttons-container {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          margin-top: 2rem;
          column-gap: 1.5rem;
          min-height: 3.5rem;

          #lucky-btn,
          #search-btn {
            border: 0.1rem solid #fff;
            border-radius: 0.3rem;
            color: #fff;
            display: block;
            margin: 0;
            padding: 0.6rem 1rem !important;
            transition: all 0.3s;
            user-select: none;
            width: fit-content;
            line-height: 1.2;
            background: transparent;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .frequent-search {
      display: flex;
      font-size: 1.2rem;

      @include breakpoint-down(mobile) {
        justify-content: center;
      }

      span {
        color: $c-white;
        margin-right: 1rem;
      }

      p {
        color: #c1c1c1;
        margin: 0;
        margin-left: 0.7rem;

        a {
          text-decoration: none;
        }
      }
    }
  }

  &.with-background-overlay::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}

.section--results {
  .search {
    form {
      border: 0.1rem solid #909090;
      border-radius: 0.5rem;

      .search__field {
        max-width: 45rem;
        width: 45rem;
        background: transparent;
      }
    }
  }

  .search-result-div {
    .no-search-results {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      &-heading {
        span {
          color: #ed1b68;
          margin-left: 1rem;
          margin-right: 0;
        }
      }
    }
  }
}

/* ___________________________________________________ LTR ___________________________________________________ */

html[dir="rtl"] {
  .search-spinner {
    right: revert !important;
    left: 1rem;
  }

  .search_tools_container {
    right: revert !important;
    left: 0;
  }

  .search {
    form {
      .search__btn {
        margin: 0 0.5rem 0 2rem !important;
      }
    }
  }

  .section-search {
    .section-content {
      .frequent-search {
        span {
          margin-right: 0;
          margin-left: 1rem;
        }

        p {
          margin-left: 0;
          margin-right: 0.7rem;
        }
      }
    }
  }

  .section--results {
    .search_container {
      height: 5.2rem;
    }
  }

  .search-heading .title,
  .no-search-results-heading {
    span {
      margin-right: 1rem !important;
      margin-left: 0 !important;
    }
  }
}

.search-heading {
  color: $c-white;
  border-radius: 0.8rem;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  column-gap: 2rem;
  height: 100%;
  width: 100%;

  .title {
    align-items: center;
    display: flex;
    font-size: 2.5rem !important;
  }
}

.search-title-wrapper {
  font-size: 40px;
  font-weight: 900;
  line-height: 60px;
  color: #eee;
  margin-bottom: 2rem !important;
  width: 95%;
  max-width: 100rem;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 30px;
    height: 30rem;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }

  .search-title {
    word-break: keep-all;
    overflow-wrap: anywhere;
    text-align: center;
    position: relative;
    z-index: 2;
    color: #d3d3d3c9;

    .fade-in {
      opacity: 0;
    }

    .fade-in-active {
      opacity: 1;
    }

    .word {
      transition: opacity 0.3s ease-in-out;
    }

    .word-0 {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.section__aisrForm {
  min-height: 170px;
}

.section__aisrResults {
  min-height: 300px;
}

#form-skeleton-loader {
  height: 170px;
}

#results-skeleton-loader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-text {
  width: 100%;
  height: 100%;
}

/* Skeleton for Results Grid */
.skeleton-grid-item {
  width: 100%;
  height: 150px;
}

/* Animation for Skeleton */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
